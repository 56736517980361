<template>
    <div class="Treasure">
        <div class="sdiv" :style="`height:${refshead}px;`">
            <p @click="jiuji(1)"></p>
            <div  @click="chestbtn(0)" :style="!drawFlag?'':'filter: grayscale(0)'">
                <span v-if="drawFlag">{{remainCount}}</span>
            </div>
            <img ref="imghead" src="https://ppyos.xijiuyou.com/202212/relief-chest.png" alt="" />
        </div>
        <div class="sdiv" :style="`height:${refshead}px;`">
            <p @click="jiuji(2)"></p>
            <div @click="chestbtn(1)" :style="!vipdrawFlag?'':'filter: grayscale(0)'">
                <span  v-if="vipdrawFlag">{{vipremainCount}}</span>
            </div>
            <img src="https://ppyos.xijiuyou.com/202212/member-chest.png" alt="" />
        </div>
        <img class="banner" @click="banners" :src="banner.bannerImg" alt="">
        <xwpopup  :isshowpopup="explain" @close="closeexplain()" :hiddenClose="true">
            <div class="explain">
                <p class="title">{{shuom == 1?"救济宝箱领取说明":'VIP宝箱领取说明'}}</p>
                <div v-if="(shuom==1)" v-html="jjtext">
                </div>
                <div v-else v-html="viptext">
                </div>
                <div @click="closeexplain">好的</div>
            </div>
        </xwpopup>
        <xwpopup  :isshowpopup="succ" @close="closesucc()" :hiddenClose="false">
            <div class="succ">
                <img src="https://ppyos.xijiuyou.com/202212/tips-img.png" alt="">
                <p>恭喜您</p>
                <p>获得 <span>{{coin}}</span> 彩贝</p>
                <div @click="closesucc">好的</div>
            </div>
        </xwpopup>
        <xwpopup  :isshowpopup="fenx" @close="closefenx()" :hiddenClose="false">
            <div class="fenx">
                <img :src="!jihuovip?'https://ppyos.xijiuyou.com/202212/member-chest-light.png':'https://ppyos.xijiuyou.com/202212/member-chest-half.png'" alt="" />
                <p v-html="jihuovip?'需要分享好友后 <br /> 才能打开救济宝箱哦':'激活泡泡鱼VIP后即可每日领取'"></p>
                <div @click="fenxreceive" v-html="jihuovip?'<img style='+'width:17px;height:17px;margin-right:5px;:'+' src='+'https://ppyos.xijiuyou.com/202212/zf-icon.png'+' /> 分享领取':'前往了解'"></div>
            </div>
        </xwpopup>
        <xwpopup  :isshowpopup="fenimg" @close="closefenimg()" :hiddenClose="true">
            <div class="fenimg">
                <img :src="imgqr.imgblob" alt="" />
                <img @click="closefenimg" src="https://ppyos.xijiuyou.com/210916/close-btn.jpg" alt="">
                <div class="fenimgbottom">
                    <div @click="wechatorqq(1)">
                        <img src="https://ppyos.xijiuyou.com/202212/icon-wechat.png" alt="">
                        <p>微信分享</p>
                    </div>
                    <div @click="wechatorqq(2)">
                        <img src="https://ppyos.xijiuyou.com/202212/icon-qq.png" alt="">
                        <p>QQ分享</p>
                    </div>
                </div>
            </div>
        </xwpopup>

        <div class="bill" ref="bill">
            <div class="billhead">
                <img crossorigin="anonymous" :src="user.avatar?user.avatar:'https://ppyos.xijiuyou.com/202206/default_avatar.png'" alt="">
                <div>
                    <p>{{user.nickname}}</p>
                    <p>我正在玩彩贝小游戏，一起来玩吧</p>
                </div>
            </div>
            <div class="qrcode">
                <div id="qrcode" ref="qrcode"></div>
                <p>扫码一起玩</p>
            </div>
            <img crossorigin="anonymous" class="share-bg" :src="packageUtilsl()" alt="">
        </div>
    </div>
</template>
<script>
import xwpopup from "../../components/Popupbox.vue";
import QRCode from "qrcodejs2";
import html2canvas from "html2canvas";
import Chest from "../../api/Chest";
import { Toast } from "vant";
import home from "../../api/home";
import game from "../../api/game";
import packageUtils from "../../utils/packageUtils"
export default {
    components: {
    xwpopup,
    // eslint-disable-next-line vue/no-unused-components
    QRCode
  },
    data(){
        return{
            explain:false,
            shuom:1,
            succ:false,
            fenx:false,
            imgqr:{},
            fenimg:false,
            remainCount:0,//数字
            drawFlag:false,//是否变灰色
            shareFlag:false,//点领取是否需要分享
            jjid:0,//救济id
            coin:0,//救济宝箱开盒子数量
            shareUrl:"",//分享二维码

            vipdrawFlag:false,
            vipremainCount:0,
            user:{},
            viptext:"",
            jjtext:"",
            banner:{},
            jihuovip:true,
            drawTips:"",
            hasVip:false,
            refshead:275,
        }
    },
    mounted(){
        const that = this;
        this.reliefChest();
        this.memberChest();
        this.myInviteLink();
        this.bannerfind();
        const time = setTimeout(()=>{
            that.refshead = that.$refs.imghead.offsetHeight;
            clearTimeout(time);
        },300)
        
    },
    methods:{
        packageUtilsl(){
            return packageUtils.getCurrentPackage()!=='com.ppy.ppyw'?'https://ppyos.xijiuyou.com/coinVip/com/jiuyou/caibeiwan/box-share-bg.png':'https://ppyos.xijiuyou.com/202212/share-bg.png'
        },
        banners(){
            try {
            window.android.createNewWeb(
                this.banner.link
            );
            } catch (e) {
                Toast("您当前app版本过低请更新app");
            }
        },
        bannerfind(){
            game.bannerfind(11).then(res=>{
                this.banner = res.data[0]
            })
        },
        wechatorqq(index){
            if(index==1){
                try {
                    window.android.shareToWX(
                        0,2,"","",this.imgqr.img,1,""
                    );
                } catch (e) {
                    Toast("您当前app版本过低请更新app");
                }
            }else{
                try {
                    window.android.shareToQQ(
                        0,2,"","",this.imgqr.img,1,""
                    );
                } catch (e) {
                    Toast("您当前app版本过低请更新app");
                }
            }
            this.fenimg = false;
            this.getPrize();
        },
        fenxreceive(){
            if(this.jihuovip){
                this.fenimg = true;
                this.fenx = false;
                Chest.reliefShareActionLog(this.jjid)
            }else{
                try {
                    window.android.createNewWeb(
                        window.location.href.split("caibeiexchange")[0] + "caibeivip"
                    );
                } catch (e) {
                    Toast("您当前app版本过低请更新app");
                }
            }
            
        },
        myInviteLink(){//分享头像
            home.myInviteLink().then(res=>{
                this.user = res.data;
            })
        },
        chestbtn(index){
            if(index == 0){
                if(this.shareFlag && this.drawFlag){
                    this.fenx = true;
                    this.jihuovip=true;
                }else{
                    this.getPrize();
                }
            }else{
                if(this.hasVip === false && this.vipremainCount === 0){
                    this.jihuovip = false;
                    this.fenx = true;
                }else{
                    if(this.vipdrawFlag){
                        this.openMemberChest();
                    }else{
                        Toast(this.drawTips);
                    }
                }
                
            }
        },
        openMemberChest(){//vip开宝箱
            Chest.openMemberChest().then(res=>{
                this.succ = true;
                this.coin = res.data.award;
            })
        },
        getPrize(){//救济开宝箱
            Chest.getPrize(this.jjid).then(res=>{
                console.log(res)
                this.succ = true;
                this.coin = res.data.prizeInfo?.coin;
            })
        },
        memberChest(){//会员宝箱
            Chest.memberChest().then(res=>{
                this.vipremainCount = res.data.remainCount;
                this.vipdrawFlag = res.data.drawFlag;
                this.viptext = res.data.text;
                this.drawTips = res.data.drawTips;
                this.hasVip = res.data.hasVip;
            })
        },
        reliefChest(){//救济宝箱
            Chest.reliefChest().then(res=>{
                this.shareUrl = res.data.shareUrl;
                this.jjid = res.data.id;
                this.remainCount = res.data.remainCount;
                this.drawFlag = res.data.drawFlag;
                this.shareFlag = res.data.shareFlag;
                this.jjtext = res.data.ruleText;
                this.qrcode();
            })
        },
        closefenimg(){
            this.fenimg = !this.fenimg;
            
        },
        async htmlcanvas(){
            const that = this;
            const bill = await html2canvas(this.$refs.bill, { useCORS: true });
            bill.toBlob(
                function (blob) {
                    that.imgqr={
                        imgblob: URL.createObjectURL(blob),
                        img: bill.toDataURL("image/png"),
                    };
                },
                "image/jpeg",
                0.95
            );
        },
        qrcode(){
            this.$refs['qrcode'].innerHTML = ''
            new QRCode("qrcode", {
                width: 165, // 二维码宽度，单位像素
                height: 165, // 二维码高度，单位像素
                text: this.shareUrl, // 生成二维码的链接
            });
            const time = setTimeout(()=>{
                this.htmlcanvas();
                clearTimeout(time);
            },300)
        },
        jiuji(index){
            this.shuom = index;
            this.closeexplain();
        },
        closeexplain(){
            this.explain = !this.explain;
        },
        closefenx(){
            this.fenx = !this.fenx;
        },
        closesucc(){
            this.succ = !this.succ;
            this.reliefChest();
            this.memberChest();
        },
    }
}
</script>
<style lang="less" scoped>
.Treasure{
    text-align: center;
    .sdiv{
        width: calc(100% - 12px);
        height: 275px;
        margin: 0 auto 4px;
        position: relative;
        img{
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
        }
        p{
            width: 130px;
            height: 40px;
            margin: 0 auto 37px;
            position: relative;
            z-index: 1;
        }
        div{
            width: 195px;
            height: 166px;
            background: url("https://ppyos.xijiuyou.com/202212/chest-1.png") no-repeat;
            background-size: 100%;
            margin: 0 auto;
            position: relative;
            filter: grayscale(1);
            position: relative;
            z-index: 1;
            span{
                display: inline-block;
                position: absolute;
                right: 5px;
                top: 7px;
                width: 28px;
                height: 28px;
                padding-top: 2px;
                background: #FF0057;
                border: 3px solid  rgba(255, 168, 198, 1);
                border-radius: 50%;
                font-size: 22px;
                font-family: DINOffcPro-Bold, DINOffcPro;
                font-weight: bold;
                color: #FFFFFF;
                line-height: 20px;
                text-align: center;
            }
        }
    }
    .sdiv:nth-child(2){
        width: calc(100% - 12px);
        height: 275px;
        div{
            width: 195px;
            height: 166px;
            background: url("https://ppyos.xijiuyou.com/202212/chest-4.png") no-repeat;
            background-size: 100%;
        }
    }
    .explain{
        width: calc(100% - 80px);
        border-radius: 15px;
        background: #ffffff;
        margin-top: 120px;
        padding: 20px 30px;
        .title{
            font-size: 16px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #444444;
            margin-bottom: 20px;
        }
        div{
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #444444;
            line-height: 25px;
            text-align: left;
        }
        div:nth-child(3){
            width: 197px;
            height: 48px;
            background: linear-gradient(180deg, #51D5FF 0%, #009CFF 100%);
            border-radius: 24px;
            font-size: 14px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
            text-align: center;
            line-height: 48px;
            margin: 0 auto;
            margin-top: 20px;
        }
    }
    .succ{
        width: 295px;
        border-radius: 15px;
        background: #ffffff;
        margin-top: 160px;
        padding: 20px 30px 30px;
        position: relative;
        img{
            width: calc(100% - 26px);
            position: absolute;
            left: 13px;
            top: -80px;
        }
        p:nth-child(2){
            font-size: 16px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 600;
            color: #444444;
        }
        p:nth-child(3){
            font-size: 16px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #444444;
            margin-top: 15px;
            display: flex;
            justify-content: center;
            align-items: center;
            span{
                font-size: 28px;
                font-family: DINOffcPro-Medi, DINOffcPro;
                font-weight: normal;
                color: #444444;
                margin: 0 3px;
            }
        }
        div{
            width: 197px;
            height: 48px;
            background: linear-gradient(180deg, #51D5FF 0%, #009CFF 100%);
            border-radius: 24px;
            font-size: 14px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
            text-align: center;
            line-height: 48px;
            margin: 20px auto 0;
        }
    }
    .fenx{
        width: calc(100% - 80px);
        border-radius: 15px;
        background: #ffffff;
        margin-top: 160px;
        padding: 0px 30px 30px;
        position: relative;
        img{
            width: 95px;
            position: absolute;
            left: calc(50% - 47px);
            top: -60px;
        }
        p{
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #444444;
            margin-top: 37px;
            margin-bottom: 23px;
        }
        div{
            width: 197px;
            height: 48px;
            background: linear-gradient(180deg, #51D5FF 0%, #009CFF 100%);
            border-radius: 24px;
            font-size: 14px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
            display: flex;
            align-items: center;
            justify-content: center;
            line-height: 48px;
            margin: 0px auto;
        }
    }
    .bill{
        width: 375px;
        height: 613px;
        position: fixed;
        left: -10000px;
        top: 0px;
        padding-top: 28px;
        .share-bg{
            width: 100%;
            position: absolute;
            left: 0;
            top: 0;
        }
        .billhead{
            position: relative;
            z-index: 1;
            width: calc(100% - 18px);
            height: 98px;
            background: #ffffff;
            border-radius: 10px;
            margin: 0 auto;
            display: flex;
            align-items: center;
            padding: 23px 14px;
            img{
                width: 52px;
                height: 52px;
                margin-right: 10px;
                border-radius: 50%;
            }
            div{
                font-size: 16px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #444444;
                text-align: left;
                p:nth-child(2){
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #444444;
                }
            }
        }
        .qrcode{
            position: relative;
            z-index: 1;
            margin: 100px auto 0;
            text-align: center;
            font-size: 16px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #444444;
        }
    }
    #qrcode{
        width: 165px;
        height: 165px;
        margin: 0 auto 26px;
    }
    .fenimg{
        width: 100vw;
        height: 100vh;
        text-align: center;
        padding-top: 80px;
        position: relative;
        img:nth-child(1){
            width: calc(100% - 136px);
            display: block;
            margin: 0 auto;
        }
        img:nth-child(2){
            width: 26px;
            height: 26px;
            margin-top: 26px;
        }
        .fenimgbottom{
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 118px;
            background: #FFFFFF;
            display: flex;
            justify-content: space-around;
            padding-top:20px;
            div{
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #444444;
                img{
                    width: 54px;
                    height: 54px;
                }
            }
        }
    }
    .banner{
        width: calc(100% - 22px);
        margin: 25px 0 0;
    }
}
</style>