import axios from "axios";
const telnumber = {
  lastPhoneNum() {
    //手机号
    return axios.get("/api/ppy/usercenter/telPhoneBill/lastPhoneNum");
  },
  configs(data) {
    //配置信息
    return axios.get("/api/ppy/usercenter/telPhoneBill/configs",{params:data});
  },
  wechatPay(data){
    return axios.get("/api/ppy/usercenter/telPhoneBill/wechatPay",{params:data});
  },
  balancePay(data){
    return axios.get("/api/ppy/usercenter/telPhoneBill/balancePay",{params:data});
  }
};
export default telnumber;
